import "./Footer.css"; // Link the CSS file here

import { Link } from "react-router-dom";
import React from "react";
import logo from "../NavBar/logo.png";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-columns">
        {/* Logo Section */}
        <div className="footer-column-logo">
          <div className="footer-logo">
           <Link to="/"> <img src={logo} alt="Company Logo" /></Link>
          </div>
        </div>

        {/* Quick Links */}
        <div className="footer-column">
          <h4 className="footer-title">Quick Links</h4>
          <ul className="footer-list">
            <Link className="li-nav-link" to="/Home">
              {" "}
              <li className="footer-item">Home</li>
            </Link>
            <Link className="li-nav-link" to="/About">
              <li className="footer-item">About</li>
            </Link>
            <Link className="li-nav-link" to="/ourTeam">
              <li className="footer-item">Our Team</li>
            </Link>
            <Link className="li-nav-link" to="/Services">
              {" "}
              <li className="footer-item">Services</li>
            </Link>
            <Link className="li-nav-link" to="/ContactUs">
              {" "}
              <li className="footer-item">Contact us</li>
            </Link>
          </ul>
        </div>

        {/* Services */}
        <div className="footer-column s-1">
          <h4 className="footer-title">Services</h4>
          <ul className="footer-list">
          <Link className="li-nav-link" to="/Services">
        
          
              <li className="footer-item">
                Education Support for Parents and Kids
              </li>
            </Link>
            
            <Link className="li-nav-link" to="/Services">
            {" "}
            <li className="footer-item">Community Leadership</li></Link>
            
            <Link className="li-nav-link" to="/Services">
            {" "} <li className="footer-item">Diversity and pathways in STEM Education</li></Link>
          </ul>
        </div>

        {/* Social Links */}
        <div className="footer-column f-1">
          <h4 className="footer-title">Social</h4>
          <ul className="footer-list">
            <li className="footer-item">
              <a href="https://www.linkedin.com/company/mind-shift-community/about/?viewAsMember=true" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </li>
            <li className="footer-item">YouTube</li>
            <li className="footer-item">Instagram</li>
            <li className="footer-item">Facebook</li>
            <li className="footer-item">X</li>
          </ul>
        </div>
      </div>
      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <p>
          Copyright ©2024 Mindshift Community. All Rights Reserved. Design by
          Xstream Minds Pvt Ltd.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
