import './OurTeam.css';

import Footer from '../Footer/Footer';
import P_1 from './Ellipse 3.png';
import P_2 from './Veena nair.png'
import React from 'react';
import Slider from '../Home/Slider';
import bgs from './bg.jpg';

function OurTeam() {
  return (
    <div>
    <Slider
    backgroundImage={bgs}
    titleTexts={[
        "Meet the Passionate Minds Behind Mindshift Community Services.",
        "Meet the Passionate Minds Behind Mindshift Community Services.",
    ]} buttonstext="Contact Us"
></Slider>


  <div className="containers">
      <div className="card_team">
        <div className="image-placeholder">
        <img src={P_1} alt='person-1'></img>
        </div>
        <div className="content">
          <h2 className="title">Founder & Executive Director</h2>
          <h3 className="name">Dr. Sohni Singh</h3>
          <p className="description">
          Sohni is a visionary leader and advocate for equality and diversity. She actively engages with senior
executives and community leaders to understand their challenges and assists them in developing
mind-shift strategies and programs aimed at empowering diverse communities.
Passionate about STEM, she holds a PhD in Biomedical Engineering with several years of experience
in research and teaching. Throughout her professional career, she has taken on various roles to
support numerous science-related programs, volunteering for initiatives aimed at Women of Colour.
She maintains strong connections with community, industry, education, and research organizations.Her commitment to empowering communities, supported by her strong STEM background, will
shape the programs and initiatives delivered by the Mindshift Community, ensuring they resonate
with the needs of those we serve.
          </p>
        </div>
      </div>

      <div className="card_team">
        
        <div className="content">
          <h2 className="title">Our Advisor</h2>
          <h3 className="name">Veena Nair</h3>
          <p  className="description">
          Veena Nair is a prominent STEM educator with over 20 years of experience teaching science-based
subjects across India, the United Arab Emirates, and Australia. She is currently the STEAM (Science,
Technology, Engineering, Arts, and Mathematics) Project Leader at Viewbank College in Melbourne.
Veena’s journey into STEM education was inspired by her father, a physics professor, who
encouraged her curiosity about how things work. She holds a degree in physics and mathematics and
initially explored various career paths before finding her passion in teaching.
Her innovative approach to STEM education has significantly increased student interest in
engineering and technology, particularly among young women and students from diverse
backgrounds. Veena’s efforts were recognized with the 2022 Prime Minister’s Prize for Excellence in
Science Teaching in Secondary Schools.</p>
        </div>
        <div className="image-placeholder">
        <img src={P_2} alt='person-2'></img>
        </div>
      </div>
      
      

      <div className="card_team ad_1">
        <div className="content adv-con">
          <h2 className="title">Our Collaborators</h2>
          <h3 className="name">Coming Soon ..</h3>
          <p className="description"></p>
        </div>
      </div>
    </div>
<Footer></Footer>
    </div>
  )
}

export default OurTeam
