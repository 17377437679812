import "./About.css";

import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import React from "react";
import Slider from "../Home/Slider";
import bgImg from "./Assests/update_bg.jpg";
import card_1 from "./Assests/still-life-business-roles-with-various-puzzle-pieces.png";
import card_2 from "./Assests/women-all-ages-posing.png";

const AboutSection = ({ title, subtitle, description, image, reverse }) => (
  <div className={`about-section ${reverse ? "reverse" : ""}`}>
    <div className="about-content">
      <h2>{title}</h2>

      <p>{description}</p>
    </div>
    <div className="about-image">
      <img src={image} alt={title} />
    </div>
  </div>
);

const About = () => {
  return (
    <>
      <Slider
        backgroundImage={bgImg}
        titleTexts={[
          "Empowering Communities, Creating Lasting Impact.",
          "Empowering Communities, Creating Lasting Impact.",
        ]}
        buttonstext="Contact Us"
      ></Slider>
      <div className="app">
        <AboutSection
          title="About Us"
          subtitle="About SRCV Services"
          description="Welcome to Mindshift community, where we strive to understand the needs of communities, create tailored strategies, and achieve lasting results. We are driven by the belief that collaboration, knowledge-sharing, and a unified vision are essential for building strong communities. Our mission is to uplift individuals and groups through creative initiatives that promote development, adaptability, and transformative progress."
          image={card_2}
          reverse
        />
        <AboutSection
          title="Our Approach"
          subtitle="At SRCV Services"
          description="At Mindshift community, we understand that every community has unique needs. We work closely with members of community and subject matter experts to tailor initiatives that address specific challenges. By incorporating expert insights and best practices, we ensure our programs are not only impactful but also sustainable. Through open communication and collaboration, we harness the collective skills and knowledge of our stakeholders, creating stronger, more supportive communities"
          image={card_1}
        />
      </div>
      <Footer></Footer>
    </>
  );
};

export default About;
