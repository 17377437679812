import './Gallery.css';

import Footer from '../Footer/Footer'
import React from 'react'
import Slider from '../Home/Slider'
import bg from './images.png'

function Gallery() {
  return (
    <div>
    <Slider
    backgroundImage={bg}
    titleTexts={[
        "Coming Soon..","Coming Soon.."
    ]} buttonstext="Contact Us"
></Slider>
<br></br>
<br></br>
<br></br>
<h1 className='g-1'>Coming Soon..</h1>
<Footer></Footer>
    </div>
  )
}

export default Gallery
