import "./Navbar.css";

import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import logo from "./logo.png";

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [contentMarginTop, setContentMarginTop] = useState(0);

  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);

    if (!isMobileMenuOpen) {
      setContentMarginTop(300);
    } else {
      setContentMarginTop(0);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
        setContentMarginTop(0);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
         <Link to="/"> <img src={logo} alt="logo" /></Link>
        </div>
        <ul className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`}>
          <Link
            className={`li-nav-link ${
              location.pathname === "/Home" ? "active" : ""
            }`}
            to="/Home"
          >
            <li className="navbar-item">Home</li>
          </Link>
          <Link
            className={`li-nav-link ${
              location.pathname === "/About" ? "active" : ""
            }`}
            to="/About"
          >
            <li className="navbar-item">About Us</li>
          </Link>
          <Link
            className={`li-nav-link ${
              location.pathname === "/Services" ? "active" : ""
            }`}
            to="/Services"
          >
            <li className="navbar-item">Services</li>
          </Link>
          <Link
            className={`li-nav-link ${
              location.pathname === "/ourTeam" ? "active" : ""
            }`}
            to="/ourTeam"
          >
            <li className="navbar-item">Our Team</li>
          </Link>
          <Link
            className={`li-nav-link ${
              location.pathname === "/Gallery" ? "active" : ""
            }`}
            to="/Gallery"
          >
            <li className="navbar-item">Gallery</li>
          </Link>
        </ul>
        <div className="navbar-right">
          <Link
            className={`li-nav-link ${
              location.pathname === "/ContactUs" ? "active" : ""
            }`}
            to="/ContactUs"
          >
            <button className="nav-book-demo">Contact Us</button>
          </Link>
          <div
            className="mobile-menu-icon"
            onClick={toggleMobileMenu}
            aria-label="Toggle mobile menu"
          >
            ☰
          </div>
        </div>
      </nav>

      <div style={{ marginTop: contentMarginTop + "px" }}></div>
    </>
  );
}

export default Navbar;
