import "./Section.css";

import React from "react";
import one from "./Assests/mission.png";
import three from "./Assests/service.jpg";
import two from "./Assests/two.png";

const Section = () => {
  return (
    <div className="unique-container">

      <section className="unique-mission-section">
        <div className="unique-mission-image">
          <img src={one} alt="Mission" />
        </div>
        <div className="unique-mission-content">
          <h2>Our Mission</h2>
          <p className="m-1">
          Building stronger communities means fostering collaboration, trust, and shared goals among members, empowering them to collectively address local needs and challenges. Mindshift Community’s mission is to support migrant parents, their children, and professional women by offering tailored programs, resources, and a platform for connection. We aim to provide community leadership programs, targeted workshops, and initiatives focused on diversity in STEM, as well as support in defining pathways to STEM.
          </p>
        </div>
      </section>

      {/* Expertise Section */}
      <section className="unique-expertise-section">
        <div className="unique-expertise-content">
          <h2>Expertise</h2>
          <p className="m-2">
          Building stronger communities relies on collaboration and expertise. By leveraging the strengths
and knowledge of both communities and organizations, we create environments that foster
growth, support, and resilience. The Mindshift Community team consists of experienced
innovators, STEM professionals, and educators who continually seek opportunities to engage with
community members and subject matter experts to shape the future of communities.</p>        </div>
        <div className="unique-expertise-image">
          <img src={two} alt="Expertise" />
        </div>
      </section>

      {/* Services Section */}
      <section className="unique-services-section">
        <div className="unique-services-image">
          <img src={three} alt="Services" />
        </div>
        <div className="unique-services-content">
          <h2>Services</h2>
          <ul className="m-3">
            <li>
            Tailored sessions for parents and children offering evidence-based programs and
workshops to support pathways to careers in Science, Technology, Engineering, and
Mathematics (STEM) and enhance diversity in these fields.
            </li>
            <li>
            Engaging workshops and activities designed for children to learn STEM concepts in a fun
            and interactive environment.
            </li>
            <li>
            Workshops for professional migrant women to provide support and guidance in their
            career journeys.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Section;
